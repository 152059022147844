/* src/styles/About.css */
.about-container {
    max-width: 800px; /* Adjust as needed for content width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 2rem; /* Add padding around the content */
  }
  
  .about-title {
    font-size: 2rem; /* Adjust font size for the title */
    margin-bottom: 1rem; /* Space below the title */
  }
  
  .about-content {
    font-size: 1rem; /* Adjust font size for the content */
    line-height: 1.6; /* Improve readability with line height */
    margin-bottom: 1.5rem; /* Space below paragraphs */
  }

  .about-images {
    display: flex; /* Display images in a row */
    justify-content: center; /* Center the images */
    gap: 1rem; /* Space between the images */
    margin-top: 2rem; /* Space above the image section */
  }
  
  .about-image {
    width: 100%; /* Full width within container */
    max-width: 400px; /* Maximum width of images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better presentation */
  }
  