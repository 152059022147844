/* src/styles/Events.css */
.events-container {
    max-width: 1000px; /* Adjust as needed for content width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 2rem; /* Add padding around the content */
  }
  
  .events-title {
    font-size: 2rem; /* Adjust font size for the title */
    margin-bottom: 1rem; /* Space below the title */
  } 
  
  .events-list {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
  }
  
  .event-item {
    display: flex; /* Enable flexbox */
    max-width: 80%;
    gap:2rem; 
    align-items: center; /* Align items vertically in the center */
    margin-bottom: 2rem; /* Space between events */
  }
  

 .event-title {
  font-size: 1.5rem; /* Adjust font size for event titles */
  margin: 0; /* No margin around the title for tight spacing */
} 

.event-title a {
  color: #008fcc; /* Link color, choose what fits your design */
  text-decoration: none; /* No underline */
}

.event-title a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

.event-details {
  font-size: 1rem; /* Adjust font size for event details */
  line-height: 1.6; /* Improve readability with line height */
  margin-top: 0.5rem; /* Space above the details */
}

.image-slider {
  position: relative;
  width: 100%;
  display: block;
  justify-content: center; /* Center the navigation buttons and images */
  align-items: center;
}

.image-slider img {
  width: 100%;  /* Full width of the container */
  height: 300px; /* Fixed height */
  /*object-fit: cover; /* This will cover the area without distorting ratios */
  border-radius: 8px; /* Optional for styling */
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  z-index: 2; /* Above the image */
}

.button.left {
  left: 10px;
}

.button.right {
  right: 10px;
}