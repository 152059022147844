/* src/styles/Footer.css */
.footer {
    background-color: #f8f9fa; /* Light gray background */
    color: #343a40; /* Dark gray text */
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #dee2e6; /* Light border at the top */
  }
  
  .footer-content {
    max-width: 1200px; /* Adjust as needed for content width */
    margin: 0 auto; /* Center the content horizontally */
  }
  
  .footer-text {
    margin: 0.5rem 0; /* Vertical spacing between footer text elements */
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 1rem 0; /* Vertical spacing between footer link elements */
  }
  
  .footer-links li {
    display: inline;
    margin: 0 0.5rem; /* Horizontal spacing between links */
  }
  
  .footer-links a {
    color: #007bff; /* Blue color for links */
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  