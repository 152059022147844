/* src/styles/Navbar.css */
.navbar {
  background-color: white;
  color: black;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between the content and links */
}

.navbar-content {
  display: flex;
  align-items: center;
}

.navbar-logo {
  width: 40px; /* Adjust the size as needed */
  height: auto;
  margin-right: 0.5rem; /* Space between the logo and title */
}

.navbar-title {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-size: 1.5rem; /* Adjust font size as needed */
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1rem; /* Space between the navigation links */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.navbar-links a {
  color: black;
  text-decoration: none;
}
