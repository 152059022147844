/* src/styles/Home.css */
.home-container {
  margin: 0 auto; /* Center the content horizontally */
  padding: 0; /* Add padding around the content */
}

.group-photo {
  margin: 0; /* Remove margin around the photo */
}

.group-photo-image {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
}

.home-content {
  text-align: center; /* Center-align the text content */
}

.home-content h1 {
  font-size: 2rem; /* Adjust font size for the title */
  margin-bottom: 1rem; /* Space below the title */
}

.home-content p {
  font-size: 1rem; /* Adjust font size for paragraphs */
  line-height: 1.6; /* Improve readability with line height */
}
